import Vue from 'vue'

Vue.filter('money', (value) => {
  if (!value) return ''
  return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('truncate', (text, length, clamp) => {
  if (!text) return
  clamp = clamp || '...';
  return text.length > length ? text.slice(0, length) + clamp : text;
})