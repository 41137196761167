import "../css/scss/base.scss"
import './commons/footer'
import './commons/filters'

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import utils from './commons/utils'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
Vue.use(VueCookies)

Sentry.init({
  dsn: window.sentry_dsn_frontend,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});

let app
let body = document.querySelector('body')
let html = document.querySelector('html')

window.True = true
window.False = false
window.None = null
window.initApp = (data) => {
  if (app) return
  app = new Vue({
    el: '#app',
    data: {
      headerClass: '',
      appClass: '',
      breadcrumbs: [
        { title: 'Explore Homes', active: true, href: '/search', allowClick: true, showIndex: false },
        { title: 'About us', active: true, href: '/about', allowClick: true, showIndex: false },
        { title: 'Get Help', active: true, href: 'https://beyondcampus.freshdesk.com', allowClick: true, showIndex: false, external: true }
      ],
      showLoginAccount: true,
      showFooter: true,
      showLanding: true,
      showCookieDisclaimer: false,
      showCookieDisclaimerTmpFocus: false,
      navigationUserMenu: {
        options: [
          { title: 'My account', href: '/myaccount', hidden: window.userIsStaff },
          { title: 'My apartment', href: '/myapartment', hidden: !window.userHasApartment },
          { title: 'My payments', href: '/payments', hidden: window.userIsStaff },
          { title: 'Hire cleaning service', href: '/myapartment?poppins=true', hidden: (!window.userHasApartment || !window.apartmentFromLandlord) },
          { title: 'Support', href: 'https://beyondcampus.freshdesk.com', external: true },
          { title: 'Logout', href: '/logout' }
        ],
        open: false,
      },
      userProfilePhoto: false
    },
    beforeMount() {

    },
    computed: {
      strHomes() {
        return this.getData().type_res != 'residences' ? 'apartments' : this.getData().type_res
      },

      btnClass() {
        return this.getData().type_res == 'residences' ? 'btn-primary-2' : ''
      },

      colorClass() {
        return this.getData().type_res == 'residences' ? 'color-primary-2' : ''
      },

      isResidence() {
        return this.getData().type_res == 'residences' ? true : false
      },

      imgResidence() {
        return this.getData().type_res == 'residences' ? '-2' : ''
      }



    },
    mounted() {
      this.checkDisclaimer()

      this.showLanding = this.getData().type_res ? false : true

      this.breadcrumbs.map(brea => {
        if (brea.title == 'Explore Homes') {
          brea.href = '/search/' + this.getData().type_res
          brea.title = 'Explore ' + this.strHomes
        }
      })

      const inputDelegate = utils.delegate('input')
      window.addEventListener('focusin', inputDelegate((el) => {
        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth < 575) {
          if (this.showCookieDisclaimer) {
            this.showCookieDisclaimerTmpFocus = true
            this.showCookieDisclaimer = false
          }
        }
      }))
      window.addEventListener('focusout', inputDelegate((el) => {
        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth < 575) {
          if (this.showCookieDisclaimerTmpFocus) {
            this.showCookieDisclaimerTmpFocus = false
            this.showCookieDisclaimer = true
          }
        }
      }))
    },
    beforeDestroy() {

    },
    methods: {
      updateUserProfilePhoto(photo) {
        this.userProfilePhoto = photo
      },
      getData() {
        return data
      },
      toggleMenu() {
        this.headerClass = !this.headerClass ? 'menu-open' : ''
        this.appClass = this.headerClass ? 'hide-high-zindex' : ''
      },
      toggleScroll() {
        this.$el.style.overflowY = this.$el.style.overflowY === 'hidden' ? 'auto' : 'hidden'
        body.style.overflowY = body.style.overflowY === 'hidden' ? 'auto' : 'hidden'
        html.style.overflowY = html.style.overflowY === 'hidden' ? 'auto' : 'hidden'
      },
      hide(options) {
        if (options.footer) this.showFooter = false
        if (options.login) this.showLoginAccount = false
      },
      // ---- Breadcrumbs methods ----
      breadcrumbsUpdateData(items) {
        this.breadcrumbs = items
        return this.breadcrumbs
      },
      breadcrumbsUpdateActiveItem(index, dontEmmit) {
        this.breadcrumbs.forEach((item) => item.active = false)
        this.breadcrumbs[index].active = true
        this.breadcrumbs[index].allowClick = true
        if (!dontEmmit) {
          this.$emit('updateActiveBreadcrumb', index)
          let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
          if (viewportWidth < 575 && this.headerClass) {
            this.toggleMenu()
          }
        }
        return this.breadcrumbs
      },
      breadcrumbsUpdateAllowItem(index, allowClick) {
        this.breadcrumbs[index].allowClick = allowClick
        return this.breadcrumbs
      },
      checkDisclaimer() {
        let cookieDisclaimer = this.$cookies.get('cookie-disclaimer')
        if (!cookieDisclaimer) {
          setTimeout(() => {
            this.showCookieDisclaimer = true
          }, 500)
        }
      },
      acceptDisclaimer() {
        this.$cookies.set('cookie-disclaimer', true, 2147483647)
        this.showCookieDisclaimer = false
      },
      toggleUserMenu() {
        this.navigationUserMenu.open = !this.navigationUserMenu.open
      }
    },
    delimiters: ['${', '}']
  })
}