import Vue from 'vue'
import utils from '../commons/utils'

Vue.component('bc-footer', {

  props: {
    showLanding: { default: true, type: Boolean },
    type: { default: false, type: Boolean },
  },
  methods: {
  },

  template: '#footer' // see on base.html
})